"use client";
import { useState, useEffect } from "react";
import { useZStore } from "@/utils/z-store";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import TextComponent from "./TextComponent";
import { useRouter } from "next/navigation";

const ProcessingProgress = () => {
  const { data, actions } = useZStore();
  const router = useRouter();
  const [isVisible, setIsVisible] = useState(true);
  const [isRetrying, setIsRetrying] = useState(false);
  const { status, progress, currentStep, error, trackerId } =
    data.processingStatus || {
      status: "idle",
      progress: 0,
      currentStep: "",
      error: undefined,
      trackerId: null,
    };

  // auto-hiding when completed
  useEffect(() => {
    if (status === "completed" || status === "failed") {
      const timer = setTimeout(() => {
        setIsVisible(false);
        actions.updateProcessingStatus({
          status: "idle",
          progress: 0,
          currentStep: "",
        });
      }, 10000);

      const fetchData = async () => {
        try {
          const { data: response } = await axios.get("/api/processed-data");
          actions.setProcessedData(response.processedData || {});
        } catch (err) {
          console.log("Failed to fetch processed data:", err);
        }
      };

      fetchData();

      return () => clearTimeout(timer);
    }
  }, [status, actions]);

  useEffect(() => {
    if (status === "processing") {
      setIsVisible(true);
    }
  }, [status]);

  // to resume polling if processing is still ongoing after a refresh
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (status === "processing" && trackerId) {
      intervalId = setInterval(async () => {
        try {
          const statusResponse = await axios.get(
            `/api/mailchimp/fetch-and-process?trackerId=${trackerId}`,
          );
          const {
            status,
            progress,
            message,
            error,
            currentStep,
            processedData,
          } = statusResponse.data;

          // Update processing status
          actions.updateProcessingStatus({
            status,
            progress,
            currentStep: message,
            error,
          });

          // Optionally update processed data
          if (processedData && Object.keys(processedData).length > 0) {
            actions.setProcessedData(processedData);
          }

          if (status === "completed" || status === "failed") {
            clearInterval(intervalId);
          }
        } catch (error) {
          console.error("Error polling resumed status:", error);
          clearInterval(intervalId);
        }
      }, 10000);
    }
    return () => clearInterval(intervalId);
  }, [status, trackerId, actions]);

  if (status === "idle" || !isVisible) return null;

  const handleClose = () => {
    setIsVisible(false);
    if (status === "completed") {
      actions.updateProcessingStatus({
        status: "idle",
        progress: 0,
        currentStep: "",
      });
    }
  };

  const handleRetry = async () => {
    setIsRetrying(true);
    try {
      // First update UI to show retrying status
      actions.updateProcessingStatus({
        status: "processing",
        progress: 0,
        currentStep: "Retrying Mailchimp data processing",
        error: "",
      });

      // Get the mailchimp key ID from your store or component state
      // This assumes you have access to the mailchimpKeyId and user email
      // You may need to modify this based on your actual state management
      const mailchimpKeyId = data.processingStatus.mailchimpKeyId;
      const email = data.userDetails?.email;

      if (!mailchimpKeyId || !email) {
        throw new Error("Missing required information for retry");
      }

      // Make the API call to retry the process
      const fetchProcessResponse = await axios.post(
        "/api/mailchimp/fetch-and-process",
        {
          email,
          mailchimpKeyId,
        },
      );

      const trackerId = fetchProcessResponse.data.trackerId;

      // Update processing status with new tracker ID
      actions.updateProcessingStatus({
        status: "processing",
        progress: 1,
        trackerId,
        currentStep: "Starting Mailchimp data processing",
      });
    } catch (error) {
      console.error("Error retrying process:", error);
      actions.updateProcessingStatus({
        status: "failed",
        progress: 0,
        currentStep: "",
        error: "Failed to retry. Please try again later.",
      });
    } finally {
      setIsRetrying(false);
    }
  };

  return (
    <div
      className={`fixed bottom-4 right-4 z-50 w-80 rounded-lg bg-white p-4 shadow-lg 
      transition-all duration-300 ease-in-out 
      ${isVisible ? "translate-y-0 opacity-100" : "translate-y-8 opacity-0"}`}
    >
      <div className="mb-2 flex items-center justify-between">
        <span className="font-medium">Processing Mailchimp Data</span>
        <div className="flex items-center gap-2">
          <span>{progress}%</span>
          {/* Close Button */}
          {(status === "completed" ||
            status == "failed" ||
            status === "processing") && (
            <button
              className="text-gray-400 hover:text-gray-600"
              aria-label="Close"
              onClick={handleClose}
            >
              <IoClose />
            </button>
          )}
        </div>
      </div>

      <div className="h-2.5 w-full rounded-full bg-gray-200">
        <div
          className="h-2.5 rounded-full bg-blue-600 transition-all duration-300"
          style={{ width: `${progress}%` }}
        />
      </div>

      <TextComponent type="body" className="mt-2 text-[#96A5B8]">
        {currentStep}
      </TextComponent>
      {error && status === "failed" && (
        <TextComponent
          type="h4"
          className="mt-2 flex items-center justify-between space-x-2"
        >
          <TextComponent type="h4" className="text-sm text-red-500">
            {error}
          </TextComponent>
          {error === "No data found" && (
            <button
              className="cursor-pointer text-kd-lte-blue"
              onClick={() =>
                router.push("/dashboard/settings/integrations?openModal=true")
              }
            >
              Add new key
            </button>
          )}
          {error !== "No data found" &&
            data.processingStatus?.mailchimpKeyId &&
            data.userDetails?.email && (
              <button
                className="cursor-pointer text-kd-lte-blue"
                onClick={handleRetry}
                disabled={isRetrying}
              >
                {isRetrying ? "Retrying..." : "Retry"}
              </button>
            )}
        </TextComponent>
      )}
    </div>
  );
};

export default ProcessingProgress;
