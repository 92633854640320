"use client";
import { EAELogo } from "@/assets/svg-icons/svg-icons";
import Button from "@/components/buttons";
import cn from "@/utils/cn";
import TextComponent from "../TextComponent";
import { IoClose } from "react-icons/io5";
import Image from "next/image";
import CloseIcon from "./assets/close-icon.svg";
import { useState } from "react";
import { useZStore } from "@/utils/z-store";

const PremiumCardUpgrade = ({ className }: { className?: string }) => {
  const [show, setShow] = useState(true);
  const { data, actions } = useZStore();
  return (
    <div
      className={cn(
        "w-64 rounded-[20px] bg-gradient-to-b bg-kd-lte-blue p-6 text-center text-white shadow-md shadow-kd-lte-blue/50",
        "w-[178.5px] h-[195.75px]",
        "xl:w-[238px] xl:h-[261px]",
        show ? "block" : "hidden",
        className,
      )}
    >
      <div
        className="absolute right-3 top-3 cursor-pointer rounded-lg hover:bg-black/10"
        onClick={() => {
          setShow(false);
        }}
      >
        <IoClose className="text-xl" />
      </div>

      <div className="mb-2 flex justify-center xl:mb-4">
        <div className="flex size-[36px] items-center justify-center rounded-xl bg-white p-2 xl:size-12 xl:rounded-[16px] xl:p-3">
          <EAELogo />
        </div>
      </div>

      <div className=" mx-auto w-[139px] xl:w-[146px]">
        <TextComponent
          type="h3"
          className="mb-[3px] text-[14px] xl:mb-2 xl:text-xl"
        >
          Premium Plan
        </TextComponent>

        <p className="mb-[11px] text-[11px] text-[#FFFFFFCC] xl:mb-6 xl:text-[12px]">
          Get access to all our exclusive features
        </p>
      </div>
      <Button
        variant="xxs"
        className="mx-auto h-[30px] w-[101.25px] text-nowrap bg-white text-kd-lte-blue xl:h-10 xl:w-[135px]"
        // disabled
      >
        Upgrade
        {/* Coming Soon */}
      </Button>
    </div>
  );
};

export default PremiumCardUpgrade;
