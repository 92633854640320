import React, { useState, useRef, useEffect } from "react";
import {
  FaChevronUp,
  FaChevronDown,
  FaEllipsisV,
  FaCheck,
} from "react-icons/fa";
import { useZStore } from "@/utils/z-store";
import Image from "next/image";
import { ReportsActiveIcon, ReportsIconBlue } from "../icons";
import cn from "@/utils/cn";
import TextComponent from "@/app/_components/TextComponent";
import { FiPlusCircle } from "react-icons/fi";
import { DashboardEditIcon } from "@/assets/svg-icons/svg-icons";
import { RiRadioButtonLine } from "react-icons/ri";
import { MdRadioButtonUnchecked } from "react-icons/md";

interface CustomReportDropdown {
  invertColors?: boolean;
  truncateLength?: number;
}

const CustomReportDropdown: React.FC<CustomReportDropdown> = ({
  invertColors = false,
  truncateLength = 15,
}) => {
  const { data, actions } = useZStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [newReportName, setNewReportName] = useState("Create New Report");
  const [editingReportId, setEditingReportId] = useState<string | null>(null);
  const [editingReportName, setEditingReportName] = useState("");
  const [showOptionsForId, setShowOptionsForId] = useState<string | null>(null);
  const [hoveredReportId, setHoveredReportId] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  // const optionsModalRef = useRef<HTMLDivElement>(null); // Remove fixed positioning ref

  const selectedReportId = data.selectedCustomReport;
  const customReports = data.customReports || [];

  const selectedReport =
    customReports.find((r) => r.id === selectedReportId)?.name || null;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        resetState();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if ((isCreatingNew || editingReportId) && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isCreatingNew, editingReportId]);

  const resetState = () => {
    setIsOpen(false);
    setIsCreatingNew(false);
    setNewReportName("Create New Report");
    setShowOptionsForId(null);
    setEditingReportId(null);
    setHoveredReportId(null);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleReportSelect = (reportId: string) => {
    actions.setSelectedCustomReport(reportId);
    setShowOptionsForId(null);
    resetState();
  };

  const handleCreateNewClick = () => {
    setIsCreatingNew(true);
    setNewReportName("");
  };

  const handleNewReportNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewReportName(e.target.value);
  };

  const handleNewReportSubmit = () => {
    if (newReportName.trim() && newReportName !== "Create New Report") {
      const newReportId = actions.addCustomReport(newReportName.trim());
      handleReportSelect(newReportId);
      setIsCreatingNew(false);
      setNewReportName("Create New Report");
    }
  };

  const handleEditReport = (reportId: string, currentName: string) => {
    setEditingReportId(reportId);
    setEditingReportName(currentName);
    setShowOptionsForId(null); // Close options menu when editing starts
  };

  const handleEditReportNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEditingReportName(e.target.value);
  };

  const handleEditReportSubmit = () => {
    if (editingReportId && editingReportName.trim()) {
      // actions.updateCustomReport(editingReportId, editingReportName.trim());
      actions.updateCustomReport(editingReportId, editingReportName.trim());
      setEditingReportId(null);
      setEditingReportName("");
      // setEditingReportId(null);
    }
  };

  const handleDeleteReport = (reportId: string) => {
    // actions.deleteCustomReport(reportId);
    actions.deleteCustomReport(reportId);
    setShowOptionsForId(null);
  };

  const handleKeyPress = (e: React.KeyboardEvent, isEditing: boolean) => {
    if (e.key === "Enter") {
      isEditing ? handleEditReportSubmit() : handleNewReportSubmit();
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    if (maxLength === 0) return text;
    return text?.length > maxLength
      ? text?.substring(0, maxLength) + "..."
      : text;
  };

  return (
    <div className="relative w-fit" ref={dropdownRef}>
      {/* Button */}
      <button
        onClick={toggleDropdown}
        className={cn(
          "flex items-center xl:space-x-2 space-x-1 justify-between xl:px-4 px-2 py-2 h-[60px] focus:outline-none cursor-pointer border-white border",
          "xl:text-[10.6px] xl:h-[60px] 2xl:text-base 2xl:rounded-2xl xl:rounded-[10.6px]",
          "h-[36px] text-[8px] flex rounded-lg",
          "2xl:w-full w-full xl:w-full md:w-full",
          invertColors
            ? "bg-[#F9FAFB]"
            : "border hover:border-kd-lte-blue text-kd-lte-blue",
          invertColors
            ? isOpen
              ? "bg-[#F9FAFB] text-[#05004E]"
              : "bg-kd-lte-blue text-white"
            : isOpen
              ? "bg-[#F9FAFB] text-kd-lte-blue"
              : "bg-[#F9FAFB] text-kd-lte-blue",
        )}
      >
        <div className="size-5 2xl:size-7">
          {invertColors ? (
            <Image
              alt="Reports icon"
              src={isOpen ? ReportsIconBlue : ReportsActiveIcon}
              className="mr-2 size-full"
            />
          ) : (
            <Image
              alt="Reports icon"
              src={ReportsIconBlue}
              className="mr-2 size-full"
            />
          )}
        </div>
        <TextComponent
          type="h4"
          className={cn(
            "grow text-nowrap text-left",
            "md:flex hidden",
            invertColors ? "" : "text-[#05004E]",
          )}
        >
          {truncateText(selectedReport || "Custom Report", truncateLength)}
        </TextComponent>
        {isOpen ? (
          <FaChevronUp
            className={cn(
              !invertColors
                ? isOpen
                  ? "text-kd-lte-blue"
                  : "text-kd-lte-blue"
                : isOpen
                  ? "text-kd-lte-blue"
                  : "text-white",
            )}
          />
        ) : (
          <FaChevronDown
            className={cn(!invertColors ? "text-kd-lte-blue" : "text-white")}
          />
        )}
      </button>

      {/* Dropdown popup */}
      {isOpen && (
        <div className="absolute right-0 z-10 mt-2 w-[280px]">
          <div className="  overflow-hidden rounded-2xl border border-[#336dff] bg-white shadow-lg">
            <div className="max-h-80 overflow-y-auto py-2 pt-0 scrollbar-hide">
              {/* Create New Report */}
              <div className="sticky top-0 z-30 flex items-center border-b border-dashed border-gray-300 bg-white p-4 hover:bg-[#EDF2F6]">
                <div className="mr-[7.5px] xl:mr-2.5">
                  <FiPlusCircle className="size-[12px] text-kd-lte-blue xl:size-[16px]" />
                </div>
                {isCreatingNew ? (
                  <>
                    <input
                      ref={inputRef}
                      type="text"
                      value={newReportName}
                      onChange={handleNewReportNameChange}
                      onKeyDown={(e) => handleKeyPress(e, false)}
                      className="w-full bg-transparent text-xs text-gray-700 focus:outline-none"
                      placeholder="Enter report name"
                    />
                    <button
                      className="ml-2 size-3 cursor-pointer rounded-[4px] border-[1.4px] border-kd-lte-blue p-[2px] xl:size-4 xl:p-[3px]"
                      onClick={handleNewReportSubmit}
                    >
                      <FaCheck className="size-full text-kd-lte-blue" />
                    </button>
                  </>
                ) : (
                  <TextComponent
                    type="caption"
                    onClick={handleCreateNewClick}
                    className="text-[#8E8E93]"
                  >
                    Create New Report
                  </TextComponent>
                )}
              </div>

              {/* Reports List */}
              {customReports.map((report) => (
                <div
                  key={report.id}
                  className="group relative flex items-center justify-between p-4 hover:bg-[#EDF2F6]"
                  onMouseEnter={() => setHoveredReportId(report.id)}
                  onMouseLeave={() => setHoveredReportId(null)}
                >
                  <button
                    onClick={() => handleReportSelect(report.id)}
                    className="flex grow items-center"
                  >
                    <div className="mr-[7.5px] xl:mr-2.5">
                      {report.id === selectedReportId ? (
                        <RiRadioButtonLine className="text-[12px] text-kd-lte-blue xl:text-[16px]" />
                      ) : (
                        <MdRadioButtonUnchecked className="text-[12px] text-kd-lte-blue xl:text-[16px]" />
                      )}
                    </div>
                    {editingReportId === report.id ? (
                      <input
                        ref={inputRef}
                        type="text"
                        value={editingReportName}
                        onChange={handleEditReportNameChange}
                        onKeyDown={(e) => handleKeyPress(e, true)}
                        className="w-full bg-transparent text-xs text-gray-700 focus:outline-none"
                      />
                    ) : (
                      <TextComponent type="caption">
                        {report.name}
                        {report.type === "shared" && " (shared)"}
                      </TextComponent>
                    )}
                  </button>

                  {/* Report Options */}
                  <div className="relative">
                    <div
                      className={cn(
                        "flex items-center",
                        (hoveredReportId === report.id ||
                          showOptionsForId === report.id) &&
                          editingReportId !== report.id
                          ? "visible"
                          : "invisible",
                        "group-hover:visible",
                      )}
                    >
                      {/* Edit button */}
                      <button
                        onClick={() => handleEditReport(report.id, report.name)}
                        className="mr-2 text-gray-400 hover:text-gray-600"
                      >
                        <DashboardEditIcon className="size-[12px] xl:size-[18px]" />
                      </button>

                      {/* Ellipsis button */}
                      <button
                        onClick={() =>
                          setShowOptionsForId(
                            showOptionsForId === report.id ? null : report.id,
                          )
                        }
                        className="text-gray-400 hover:text-gray-600"
                      >
                        <FaEllipsisV size={12} />
                      </button>
                    </div>

                    {/* Options Popup */}
                    {showOptionsForId === report.id && (
                      <div className="absolute right-0 top-full z-20 mt-1 w-[123px] origin-top-right overflow-hidden rounded-lg border border-kd-lte-blue bg-white shadow-lg">
                        <button
                          onClick={() =>
                            handleEditReport(report.id, report.name)
                          }
                          className="block w-full px-4 py-2 text-left text-xs text-gray-700 hover:bg-gray-100"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleReportSelect(report.id)}
                          className="block w-full px-4 py-2 text-left text-xs text-gray-700 hover:bg-gray-100"
                        >
                          Select
                        </button>
                        <button
                          onClick={() =>
                            handleEditReport(report.id, report.name)
                          }
                          className="block w-full px-4 py-2 text-left text-xs text-gray-700 hover:bg-gray-100"
                        >
                          Rename
                        </button>
                        <button
                          onClick={() => handleDeleteReport(report.id)}
                          className="block w-full border-t border-dashed border-gray-200 px-4 py-2 text-left text-xs text-[#FA5A7D] hover:bg-gray-100"
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomReportDropdown;
