"use client";
import Button from "@/components/buttons";
import Image from "next/image";
import { usePathname } from "next/navigation";
import ExportReportIcon from "../icons/export-report.svg";
import Link from "next/link";
import cn from "@/utils/cn";
import UserProfileSidebar from "./UserProfileSidebar";
import { useEffect, useState } from "react";
import { useZStore } from "@/utils/z-store";
import DashboardDropdown from "./DashboardDropdown";
import CustomReportDropdown from "./CustomReportDropdown";
import { useSession } from "next-auth/react";
import axios from "axios";
import { NewEditIcon } from "../icons";
import TextComponent from "@/app/_components/TextComponent";

const Header = ({ className }: { className?: string }) => {
  const { data, actions } = useZStore();
  const { data: session } = useSession();
  const pathName = usePathname();
  const route = pathName.split("/")[2];

  const [profilePicture, setProfilePicture] = useState(
    session?.user?.image || "",
  );

  useEffect(() => {
    const getProfilePicture = async () => {
      try {
        const response = await axios.get("/api/profile-picture");
        setProfilePicture(response.data.profilePicture);
        actions.setProfilePicture(response.data.profilePicture);
      } catch (error) {
        console.error("Error fetching profile picture:", error);
      }
    };
    if (session && session.user.id) getProfilePicture();
  }, [session, actions]);

  if (route.toLocaleLowerCase() === "edit") return <EditDashboardHeader />;
  if (route.toLocaleLowerCase() === "form-builder")
    return <FormBuilderHeader />;

  if (route.toLocaleLowerCase() == "report-builder") {
    return <EditReport title="Edit Report" />;
  }

  if (route.toLocaleLowerCase() == "download-report") {
    return <DownloadReportHeader title="Custom Report" />;
  }
  if (route.toLocaleLowerCase() === "reports") {
    return <ReportsHeader title="Reports" />;
  }
  const modalName = data.modal.modalName;
  const isModalActive =
    modalName === "mailchimp-api-key" || modalName === "dashboard-welcome";

  return (
    <div
      className={cn(
        "flex h-[80px] items-center justify-between bg-white px-5 xl:h-[120px] 2xl:px-10",
        isModalActive ? "bg-black/[20%] pointer-events-none opacity-50" : "",
        className,
      )}
      style={isModalActive ? { filter: "grayscale(100%)" } : {}}
    >
      <div className="flex">
        <Text className="font-semibold capitalize text-kd-lte-blue">
          {route == "home" ? "Dashboard" : route}
        </Text>
      </div>

      <div className="flex items-center justify-center space-x-2">
        {route.toLocaleLowerCase() !== "settings" && (
          <div className="ml-10 flex space-x-2.5 xl:space-x-[20px]">
            <div className="">
              <DashboardDropdown invertColors={false} />
            </div>

            <div className="my-auto">
              <Link
                href="/dashboard/edit"
                className="group flex size-fit items-center rounded-[13px] hover:bg-kd-lte-blue xl:rounded-[17px]"
              >
                <div className="relative flex size-9 items-center justify-center rounded-[13px] bg-kd-lte-blue xl:size-12 xl:rounded-[17px]">
                  <Image
                    alt="Edit icon"
                    className="absolute inset-0 m-auto size-[20px] xl:size-[26px]"
                    src={NewEditIcon}
                    width={100}
                    height={100}
                  />
                </div>
                <TextComponent
                  type="h4"
                  className=" mr-2 hidden text-white transition-all duration-200 ease-in-out group-hover:inline xl:mr-[11px]"
                >
                  Edit
                </TextComponent>
              </Link>
            </div>
          </div>
        )}

        <UserProfileSidebar
          userName={data?.userDetails?.userName || "User"}
          userRole={data?.userDetails?.role || "user"}
          userEmail={data?.userDetails?.email || "user"}
          profilePicture={profilePicture}
          userImage={session?.user?.image}
        />
      </div>
    </div>
  );
};

export default Header;

const DownloadReportHeader = ({ title }: { title: string }) => {
  return <></>;
};

const EditDashboardHeader = ({
  title = "Edit Dashboard",
}: {
  title?: string;
}) => {
  return (
    <div className=" flex  h-[80px] w-full items-center justify-between bg-kd-lte-blue px-[23px] xl:h-[120px] xl:px-10">
      <Text className="capitalize text-white">{title}</Text>
      <div className="">
        <DashboardDropdown showCreateOption={true} invertColors={true} />
      </div>
    </div>
  );
};

const EditReport = ({ title = "Edit Report" }: { title?: string }) => {
  const { data } = useZStore();
  return (
    // <div className=" flex h-20 w-full items-center justify-between bg-kd-lte-blue px-[23px] md:h-[60px] xl:h-[120px] xl:px-10">
    <div
      className={cn(
        "flex w-full items-center justify-between bg-kd-lte-blue px-[23px] h-[80px] xl:px-10 xl:h-[120px]",
      )}
    >
      <Text className="capitalize text-white">{title}</Text>
      {!data.modal.isOpen && (
        <CustomReportDropdown invertColors={true} truncateLength={55} />
      )}
    </div>
  );
};

const FormBuilderHeader = ({
  title = "Edit Audit Form",
}: {
  title?: string;
}) => {
  return (
    <div className=" flex h-[80px] w-full items-center bg-kd-lte-blue px-[23px] xl:h-[120px] xl:px-10">
      {/* <Text className="text-[18px] capitalize text-white xl:text-[24px] 2xl:text-[38px]"> */}
      <TextComponent type="h2" className="text-white">
        {title}
      </TextComponent>
      {/* </Text> */}
    </div>
  );
};

const Text = ({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <TextComponent type="h2" className={cn("text-kd-lte-blue", className)}>
      {children}
    </TextComponent>
    // <h1
    //   className={cn(
    //     `text-left text-[18px] font-semibold leading-[25.2px] md:text-[18px] md:leading-[25.2px] xl:text-[24px] xl:leading-[33.6px] 2xl:text-[36px] 2xl:leading-[50.4px]`,
    //     className,
    //   )}
    // >
    //   {children}
    // </h1>
  );
};

const ReportsHeader = ({ title = "Reports" }: { title?: string }) => {
  const { data } = useZStore();
  const { data: session } = useSession();

  return (
    <div className=" flex h-20 w-full items-center justify-between bg-white px-[23px] shadow-sm md:h-[80px] xl:h-[120px]">
      <div className="flex space-x-2">
        <Text className="capitalize text-kd-lte-blue">{title}</Text>
      </div>
      <div className="flex items-center space-x-2">
        <div className="flex space-x-[9px] xl:space-x-5">
          <Button
            variant="secondary"
            className="h-[36px] w-fit space-x-1 rounded-[8px] border bg-[#F9FAFB] px-2 text-base text-kd-lte-blue hover:border-kd-lte-blue xl:h-[60px] xl:space-x-2 xl:rounded-2xl"
            onClick={() => {
              const baseUrl = window.location.origin; // Gets the base URL of your site
              window.open(
                `${baseUrl}/view-report/ur-${data.selectedCustomReport}?uid=${data.userId}`,
                "_blank",
                "noopener,noreferrer",
              );
            }}
          >
            <Image
              alt=""
              src={ExportReportIcon}
              className="size-[21px] xl:size-[21px]"
            />
            <TextComponent className="hidden text-[#05004E] md:flex" type="h4">
              Export Report
            </TextComponent>
          </Button>

          <CustomReportDropdown invertColors={false} truncateLength={55} />
          <div className="my-auto">
            <Link
              href="/dashboard/report-builder"
              className="group flex size-fit items-center rounded-[13px] hover:bg-kd-lte-blue xl:rounded-[17px]"
            >
              <div className="relative flex size-9 items-center justify-center rounded-[13px] bg-kd-lte-blue xl:size-12 xl:rounded-[17px]">
                <Image
                  alt="Edit icon"
                  className="absolute inset-0 m-auto size-[20px] xl:size-[26px]"
                  src={NewEditIcon}
                  width={100}
                  height={100}
                />
              </div>
              <TextComponent
                type="h4"
                className=" mr-2 hidden text-white transition-all duration-200 ease-in-out group-hover:inline xl:mr-[11px]"
              >
                Edit
              </TextComponent>
            </Link>
          </div>

          {/* <ShareDropdown /> */}
        </div>
        <UserProfileSidebar
          userName={data?.userDetails?.userName || "User"}
          userRole={data?.userDetails?.role || "user"}
          userEmail={data?.userDetails?.email || "user"}
          profilePicture={data?.profilePicture || session?.user?.image}
          userImage={session?.user?.image}
        />
      </div>
    </div>
  );
};
